import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { reject, omit, forEach, map } from 'lodash';

import Page from './page';
import { colors } from '../lib/styles';
import SimpleList from '../components/simple-list';

import { browseFunctionalLimitations } from '../state/app-data';
import { submitPRO } from '../state/pro-forms';
import { apiFetch, makeShadowOptions } from '../lib/fetch';
import ProWrapper from '../lib/pro-wrapper';

const styles = {
  appBar: {
    height: '8.75rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  backButton: {
    left: '-1.25rem',
    height: '3.6875rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3.6875rem',
  },
  backButtonIcon: {
    height: '3.6875rem',
    width: '3.6875rem',
  },
  continueBtn: {
    background: colors.secondaryColor,
    marginBottom: '7.5vw',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    color: colors.black,
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.8rem',
  },
  nextBtn: {
    backgroundColor: colors.secondaryColor
  },
  nextButtonLabel: {
    color: colors.black,
    fontSize: '1.6rem',
    padding: 0,
    textTransform: 'none',
  },
  subtitle: {
    alignItems: 'center',
    backgroundColor: colors.darkGrayText,
    color: colors.white,
    display: 'flex',
    fontSize: '1.5rem',
    height: '3rem',
    justifyContent: 'center',
  },
};

const { questionBackground } = colors;

const AppBarContinueButton = props => (
  <Button
    onClick={props.onClick}
    style={{ ...styles.nextButton, ...styles.nextButtonLabel }}
  >Next
  </Button>
);
AppBarContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class FunctionalLimitations extends Page {
  constructor(props) {
    super(props);
    this.state = {
      functionalLimitations: [],
      selectedItems: {},
      submitting: false,
    };

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentWillMount() {
    if (this.props.bodyPain[this.getTrackIndex()]) {
      const selectedItems = {};
      forEach(this.props.bodyPain[this.getTrackIndex()], (item) => {
        selectedItems[item.functional_limitations_id] = true;
      });
      this.setState({ functionalLimitations: this.props.bodyPain[this.getTrackIndex()], selectedItems });
    }
    if (!this.props.functionalLimitations) {
      this.props.browseFunctionalLimitations();
    }
  }

  handleAddItem(id, action, name) {
    this.setState({
      functionalLimitations: [...this.state.functionalLimitations, { functional_limitations_id: id, name }],
      selectedItems: { ...this.state.selectedItems, [id]: true },
    });
  }

  handleRemoveItem(id, action, name) {
    const newFactors = [...this.state.functionalLimitations];
    const newItems = { ...this.state.selectedItems };
    this.setState({
      functionalLimitations: reject(newFactors, { functional_limitations_id: id, name }),
      selectedItems: omit(newItems, [id]),
    });
  }

  async handleContinue() {
    const { id } = this.props.location.query;
    const { user, bodyPain } = this.props;
    const { functionalLimitations, submitting } = this.state;
    if (submitting) return;
    this.setState({ submitting: true });

    try {
      const newPainData = {
        painHx: [
          ...bodyPain[5],
        ],
        aggravatingFactors: map(bodyPain[6], (i) => { return { aggravating_factors_id: i.aggravating_factors_id }; }),
        alleviatingFactors: map(bodyPain[7], (i) => { return { alleviating_factors_id: i.alleviating_factors_id }; }),
        functionalLimitations: map(functionalLimitations, (i) => { return { functional_limitations_id: i.functional_limitations_id }; }),
      };
      const addPainOptions = makeShadowOptions(newPainData, user.token, 'POST');
      const newPain = await apiFetch(`/users/${user.id}/pain_hx_full`, addPainOptions);
      const formattedDataForPro = {
        painHx: {
          ...bodyPain[5],
        },
        aggravatingFactors: map(bodyPain[6], i => i.name),
        alleviatingFactors: map(bodyPain[7], i => i.name),
        functionalLimitations: map(functionalLimitations, i => i.name),
        pain_hx_id: newPain.id,
      };
      await this.props.submitPRO({ userId: user.id, id }, {
        pro_type: 'BODY-PAIN',
        pro_data: { data: formattedDataForPro },
      }, user);
      this.forwardWithQuery();
    } catch (e) {
      this.setState({ submitting: false, error: e });
      console.error(e);
    }
  }

  handleBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  render() {
    const { functionalLimitations } = this.props;

    return (
      <ProWrapper
        header="Functional Limitations"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        btnStyle={styles.nextBtn}
      >
          <div style={styles.subtitle}>
            Select the limitations your pain has caused
          </div>
          {functionalLimitations ? (
            <SimpleList
              items={functionalLimitations}
              selectedItems={this.state.selectedItems}
              onAddItem={this.handleAddItem}
              onRemoveItem={this.handleRemoveItem}
            />
          ) : null}
      </ProWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    proForms: { bodyPain },
    appData: {
      functionalLimitations,
    },
    user,
  } = state;

  return {
    functionalLimitations,
    bodyPain,
    user,
  };
}

FunctionalLimitations.defaultProps = {
  functional_limitations: {},
};

export default connect(mapStateToProps, {
  browseFunctionalLimitations,
  submitPRO,
})(FunctionalLimitations);
