import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Slider } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Page from './page';
import QuestionLayout from '../layouts/question';
import AppBar from '../components/app-bar';
import { updatePRO } from '../state/pro-forms';
import { painScaleColors, colors } from '../lib/styles';
import ProWrapper from '../lib/pro-wrapper';

const { lightBrightBlue, almostBlack, black, highlightBright } = colors;

const styles = {
  root: {
    '& .MuiSlider-markLabel[data-index="0"]': {
      transform: 'translateX(0%)',
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      transform: 'translateX(-100%)',
    },
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
    justifyContent: 'space-between',
    backgroundColor: highlightBright,
  },
  h4: {
    color: black,
    fontWeight: 600,
    margin: '0px',
    fontSize: '1.875rem',
    textAlign: 'left',
    paddingTop: '0.9375rem',
  },
  paragraph: {
    textAlign: 'center',
    marginLeft: '15px',
    marginRight: '15px',
    color: almostBlack,
    fontSize: '1.5rem',
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    fontSize: '14px',
  },
  sliderStyle: {
    flexGrow: 1,
    marginBottom: 24,
  },
  sliderLabel: {
    fontSize: 14,
    maxWidth: 100,
    textAlign: 'center',
  },
  bar: {
    backgroundColor: lightBrightBlue,
  },
  questionnaire: {
    margin: '0px 30px',
    position: 'relative',
  },
  nextBtn: {
    backgroundColor: colors.secondaryColor
  },
};
class BodyPainPro4 extends Page {
  constructor(props) {
    super(props);
    this.handleContinue = this.handleContinue.bind(this);

    this.state = {
      theme: this.getSliderTheme(0),
      value: 0,
    };
  }

  getSliderTheme = (value) => {
    return {
      color: painScaleColors[value],
    };
  }

  handleValueChange = (event, value) => {
    const theme = this.getSliderTheme(value);
    this.setState({ theme });
    this.props.updatePRO({
      type: 'bodyPain',
      position: this.getTrackIndex(),
      value,
    });
  }

  handleContinue() {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { theme } = this.state;
    const value = this.props.bodyPain[this.getTrackIndex()] || this.state.value;
    const { bodyPain } = this.props;
    const { locations } = bodyPain[1];
    const header = locations.length === 1 ? `${locations[0].name}` : `${locations[0].name} to ${locations[1].name}`;

    return (
      <div style={{ height: '100%' }}>
        <ProWrapper
          header={header}
          currentPage={this.getCurrentPage()}
          totalPages={this.getTotalPages()}
          onContinue={this.handleContinue}
          btnStyle={styles.nextBtn}
        >
          <div style={styles.contentWrapper}>
            <div style={styles.questionnaire}>
              <h4 style={styles.h4}>Rate this pain</h4>
              <p style={styles.paragraph}>Use slider to give a pain intensity on a scale from 1-10. 0 being no pain and 10 being extreme agony.</p>
              <Slider
                min={0}
                max={10}
                value={0 || value}
                valueLabelDisplay="on"
                onChange={this.handleValueChange}
                style={{ ...styles.slider, ...theme }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
              No pain
                </div>
                <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
              Worst imaginable pain
                </div>
              </div>
            </div>
          </div>
        </ProWrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { bodyPain } } = state;
  return { user, bodyPain };
}

BodyPainPro4.proptypes = {
  user: PropTypes.object.isRequired,
  bodyPain: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(BodyPainPro4);
