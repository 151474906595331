import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { reject, omit, forEach } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import SimpleList from '../components/simple-list';

import { browseAggravatingFactors } from '../state/app-data';
import { updatePRO } from '../state/pro-forms';
import ProWrapper from '../lib/pro-wrapper';

const styles = {
  appBar: {
    height: '8.75rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  backButton: {
    left: '-1.25rem',
    height: '3.6875rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3.6875rem',
  },
  backButtonIcon: {
    height: '3.6875rem',
    width: '3.6875rem',
  },
  continueBtn: {
    background: colors.secondaryColor,
    marginBottom: '7.5vw',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    color: colors.black,
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.8rem',
  },
  nextButton: {
    position: 'absolute',
    right: 5,
    textAlign: 'right',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  nextButtonLabel: {
    color: colors.black,
    fontSize: '1.6rem',
    padding: 0,
    textTransform: 'none',
  },
  subtitle: {
    alignItems: 'center',
    backgroundColor: colors.darkGrayText,
    color: colors.white,
    display: 'flex',
    fontSize: '1.5rem',
    height: '3rem',
    justifyContent: 'center',
  },
  nextBtn: {
    backgroundColor: colors.secondaryColor
  },
};

const AppBarContinueButton = props => (
  <Button
    onClick={props.onClick}
    style={{ ...styles.nextButton, ...styles.nextButtonLabel }}
  >Next
  </Button>
);
AppBarContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class AggravatingFactors extends Page {
  constructor(props) {
    super(props);
    this.state = {
      aggravatingFactors: [],
      selectedItems: {},
    };
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentWillMount() {
    if (this.props.bodyPain[this.getTrackIndex()]) {
      const selectedItems = {};
      forEach(this.props.bodyPain[this.getTrackIndex()], (item) => {
        selectedItems[item.aggravating_factors_id] = true;
      });
      this.setState({ aggravatingFactors: this.props.bodyPain[this.getTrackIndex()], selectedItems });
    }
    if (!this.props.aggravatingFactors) {
      this.props.browseAggravatingFactors();
    }
  }

  handleAddItem(id, action, name) {
    this.setState({
      aggravatingFactors: [...this.state.aggravatingFactors, { aggravating_factors_id: id, name }],
      selectedItems: { ...this.state.selectedItems, [id]: true },
    });
  }

  handleRemoveItem(id, action, name) {
    const newFactors = [...this.state.aggravatingFactors];
    const newItems = { ...this.state.selectedItems };
    this.setState({
      aggravatingFactors: reject(newFactors, { aggravating_factors_id: id, name }),
      selectedItems: omit(newItems, [id]),
    });
  }

  handleContinue() {
    this.props.updatePRO({ type: 'bodyPain', position: this.getTrackIndex(), value: this.state.aggravatingFactors });
    this.forwardWithQuery(this.props.location.query);
  }

  handleBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  render() {
    const { aggravatingFactors } = this.props;
    return (
      <ProWrapper
        header="Aggravating Factors"
        currentPage={this.getCurrentPage()}
        totalPages={this.getTotalPages()}
        onContinue={this.handleContinue}
        btnStyle={styles.nextBtn}
      >
        <div>
          <div style={styles.subtitle}>
            Select the factors that cause you pain
          </div>
          {aggravatingFactors ? (
            <SimpleList
              items={aggravatingFactors}
              selectedItems={this.state.selectedItems}
              onAddItem={this.handleAddItem}
              onRemoveItem={this.handleRemoveItem}
            />
          ) : null}
        </div>
      </ProWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    proForms: { bodyPain },
    appData: {
      aggravatingFactors,
    },
    user,
  } = state;
  return {
    aggravatingFactors,
    bodyPain,
    user,
  };
}

AggravatingFactors.defaultProps = {
  aggravating_factors: {},
};

export default connect(mapStateToProps, {
  browseAggravatingFactors,
  updatePRO,
})(AggravatingFactors);
