import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import heic2any from 'heic2any';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteIcon from '@material-ui/icons/Delete';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { cordovaTakePicture } from '../state/cordova';
import { updatePRO } from '../state/pro-forms';

const styles = {
  bar: {
    background: colors.lightBrightBlue,
  },
  deleteIcon: {
    fontSize: '30px',
    color: '#F00',
    padding: 0,
  },
  centerContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
  },
  contentWrapper: {
    flexGrow: 1,
    marginLeft: 40,
    marginRight: 40,
    overflowX: 'hidden',
    position: 'relative',
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    maxHeight: '580px',
  },
  input: {
    display: 'none',
  },
  pageInstructions: {
    fontSize: '22px',
    marginTop: 30,
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, '
      + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  uploadBtn: {
    marginBottom: '20px',
    fontSize: '16px',
  }
};

/* global FileReader */
class WoundPro2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      files: [],
      count: 0,
    };
    this.handleFileReader = this.handleFileReader.bind(this);
    this.handleUploadImages = this.handleUploadImages.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleFileReader(image) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  async handleUploadImages(e) {
    if (e.preventDefault) {
      e.preventDefault();
    }
    const { count } = this.state;
    const files = e.target.files || e.dataTransfer.files;
    this.setState({ count: count + files.length });
    try {
      Array.from(files).forEach(async (file) => {
        let convertedHeic;
        if (file.type === 'image/heic') {
          const blob = new Blob([file]);
          convertedHeic = await heic2any({
            blob,
            toType: 'image/jpeg',
            quality: 0.6,
          });
          const newName = await file.name.slice(0, -5);
          convertedHeic = new File([convertedHeic], `${newName}.jpeg`, { type: 'image/jpeg' });
        }

        const fileData = await this.handleFileReader(convertedHeic || file);
        const newFile = new Blob([convertedHeic || file], { type: 'form' });
        this.setState(prev => ({
          images: [...prev.images, fileData],
          files: [...prev.files, newFile],
        }));
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleRemoveImage = (e) => {
    const { images } = this.state;
    const removedImage = images.filter(image => image !== e);
    this.setState({
      images: removedImage,
    });
  };

  handleContinue = () => {
    this.props.updatePRO({ type: 'wound', position: this.getTrackIndex(), value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { images, count } = this.state;
    const { classes, t } = this.props;

    const disabledBtn = !images.length;
    return (
      <QuestionLayout
        primaryBtnDisabled={disabledBtn}
        primaryBtnLabel={t('saveImages')}
        primaryBtnOnTouchTap={this.handleContinue}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={t('selectImage')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.pageInstructions}>
            {t('pleaseTakePictureOfWoundToUpload')}
          </div>
          <div className={classes.centerContent}>
            <input
              accept=".jpg, .jpeg, .pdf, .png, .heic"
              className={classes.input}
              id="image-upload-button"
              multiple
              type="file"
              onChange={this.handleUploadImages}
            />
            <label htmlFor="image-upload-button">
              <Button variant="contained" color="primary" component="span" className={classes.uploadBtn}>
                {t('addImages')}
              </Button>
            </label>
            <div>
              {count !== images.length ? (
                <CircularProgress size={100} style={{ marginTop: 100 }} />
              ) : (
                <ImageList
                  className={classes.imageList}
                  cols={1.2}
                >
                  {images.map(image => (
                    <ImageListItem style={{ height: 'auto', width: '95%' }} key={image}>
                      <img src={image} alt="" height="auto" width="100%" />
                      <ImageListItemBar
                        position="top"
                        classes={{
                          root: classes.titleBar,
                          title: classes.title,
                        }}
                        actionIcon={(
                          <IconButton onClick={() => this.handleRemoveImage(image)}>
                            <DeleteIcon className={classes.deleteIcon} />
                          </IconButton>
                      )}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>)}
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    wound,
    user,
  } = state;

  return { wound, user };
};

export default connect(mapStateToProps, {
  cordovaTakePicture,
  updatePRO,
})(withStyles(styles)(withTranslation('woundPro2')(WoundPro2)));
