import SocketIO from 'socket.io-client';

import config from './config';

import EventEmitter from 'events';
import { offer } from './state/rtc';
import { apiFetch } from './lib/fetch';

let socket, store;

export const socketEvents = new EventEmitter();

export const VITAL_CORE_MSGS_IN = {
  enableVitalCore: 'enableVitalCore',
};

export const VITAL_CORE_MSGS_OUT = {
  initializingCoreModule: 'initializingCoreModule',
  pendingPatientApproval: 'pendingPatientApproval',
  measuring: 'measuring',
  measured: 'measured',
  results: 'results',
  error: 'error',
};

async function establishSocket() {
  if (!socket) {
    const { token } = await apiFetch('/users/socket/me');
    socket = SocketIO(`${config.API_URL}`);
    socket._token = token;
    window.socket = socket; //devtools
  }
  return socket;
}
export function subscribeToUser(id) {
  establishSocket()
  .then(() => {
    if (id) {
      setTimeout(() => {
        socket.emit('subscribe_patient', { id, token: socket._token });
        connectEmitter(socket, id);
      }, 1000);

      socket.on('reconnect', async () => {
        const { token } = await apiFetch('/users/socket/me');
        socket._token = token;
        socket.emit('subscribe_patient', { id, token });
      });
    }
  });
}

export function subscribeWithToken(token, id) {
  establishSocket()
  .then(() => {
    if(token) {
      setTimeout(() => {
        socket.emit('subscribe_token', { token });
        connectEmitter(socket, id);
      }, 1000);

      socket.on('reconnect', async () => {
        socket.emit('subscribe_token', { token });
      });
    }
  });
}

function connectEmitter(socket, id) {
  establishSocket()
  .then(() => {
    socket.on(`patient_${id}`, (data) => {
      if(data.offer && data.type !== 'RTC_OFFER_RESTART') {
        store.dispatch(offer(data));
        socketEvents.emit('RTC_OFFER', data);
      }
      else if (data.type === 'RTC_OFFER_RESTART') {
        socketEvents.emit('RTC_OFFER_RESTART', data);
      }
      else if (data.type === 'OFFERER_ICECANDIDATE') {
        socketEvents.emit('OFFERER_ICECANDIDATE', data);
      }
      else if ( data.type === 'PROVIDER_HEARTBEAT') {
        socketEvents.emit('PROVIDER_HEARTBEAT', data);
      }
      else if (data.type === 'PROVIDER_SCREENSHARE') {
        socketEvents.emit('PROVIDER_SCREENSHARE', data);
      }
      else if (data.type === 'END_SESSION') {
        socketEvents.emit('END_SESSION', data);
      }
      else if (data.type === 'PROVIDER_HANGUP') {
        socketEvents.emit('PROVIDER_HANGUP', data);
      }
      else if (data.type === 'OFFERER_CALL_REQUEST') {
        // store.dispatch(callRequest(data));
        setTimeout(() => {
          socketEvents.emit('OFFERER_CALL_REQUEST', data);
        }, 200);
      }
      else if (data.type === 'CLINIC-SMS') {
        socketEvents.emit('CLINIC-SMS', data);
      }
      else if (data.type === 'CLINIC-IS-TYPING') {
        socketEvents.emit('CLINIC-IS-TYPING', data);
      }
      else if (data.type === 'CLINIC-IS-NOT-TYPING') {
        socketEvents.emit('CLINIC-IS-NOT-TYPING', data);
      } else if (VITAL_CORE_MSGS_IN[data.type]) {
        console.log('emitting:', VITAL_CORE_MSGS_IN[data.type]);
        socketEvents.emit(VITAL_CORE_MSGS_IN[data.type], data);
      }
    });
  });
}

export function messageClinic(clinic_id, message) {
  establishSocket()
  .then(() => {
    socket.emit('message_clinic', { clinic_id, message });
  });
}

export function socketInitializer(aStore) {
  store = aStore;
}

export default socket;
